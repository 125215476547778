import styled from "styled-components";

const FeedbackCSS = styled.div`

#feedbackPage img {
  margin-bottom: 50px;
  margin-top: 50px;
}

button.btn.btn-warning {
  padding: 15px 50px;
  border-radius: 5px;
  background: linear-gradient(180deg, #FFA238 0%, #8A4A00 100%);
  color: #ffffff;
  margin-top: 15px;
  border: 0;
  font-size: 16px;
}

  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .pt-2 {
    padding-top: 0.5rem !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  label {
    display: inline-block;
  }
  .rating > .half:before {
    content: "\f089";
    position: absolute;
  }
  .rating > label:before {
    margin: 7px 5px;
    font-size: 25px;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
  }
  .rating > label {
    color: #ddd;
    float: right;
  }
  .fo:after {
    clear: both;
  }
  .fo:after,
  .fo:before {
    content: "";
    display: table;
  }
  .fw6 {
    font-weight: 600;
  }
  .pull-left {
    float: left;
  }
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  .rating:not(:checked) > label:hover,
  .rating:not(:checked) > label:hover ~ label,
  .rating > input:checked + label:hover,
  .rating > input:checked ~ label,
  .rating > input:checked ~ label:hover,
  .rating > input:checked ~ label:hover ~ label,
  .rating > label:hover ~ input:checked ~ label {
    color: #dd8010;
  }
  .rating > input {
    display: none;
  }
  .feedback form button.btn.btn-warning {
    color: #fff;
    padding: 5px 30px;
    border-radius: 25px;
    background: #dd8010;
    border-color: #dd8010;
  }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .btn-warning {
    color: #ffffff;
    background-color: #dd8010;
    border-color: #dd8010;
    border-radius: 5px;
    padding: 10px;
  }
  .btn-warning:hover {
    color: #ffffff;
    background-color: #dd8010;
    border-color: #dd8010;
  }
  .rating {
    float: left;
  }
`;

export default FeedbackCSS;
